import 'css/app.scss';
import $ from 'jquery';
import i18next from 'i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';
import i18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import jqueryI18next from 'jquery-i18next';
import Swiper from 'swiper';
import ScrollReveal from 'scrollreveal';
require('lightgallery');
window.jQuery = $;
window.$ = $;

$(document).ready(function(){
  $('#header__icon').click(function(e){
    e.preventDefault();
    $('body').toggleClass('with--sidebar');
  });

  $('#site-cache').click(function(e){
      $('body').removeClass('with--sidebar');
  })

  i18next
    .use(i18nextXHRBackend)
    .use(i18nextBrowserLanguageDetector)
    .init({
      fallbackLng: 'fr',
      backend: {
        loadPath: 'assets/translations/{{lng}}.json',
        crossDomain: true
      }
    }, function(err, t) {
        jqueryI18next.init(i18next, $);
        $('body').localize();
        window.cookieconsent.initialise({
          "palette": {
            "popup": {
              "background": "#000"
            },
            "button": {
              "background": "transparent",
              "text": "#8acbce",
              "border": "#8acbce"
            }
          }
        })
    });

    $('#locationLink').hover(() => {
      if($(window).width() <= 750) {
        $('locationSubnavMobile').addClass('open');
        return;
      }
      $('#locationSubnav').addClass('open');
      $('#locationLink').addClass('open');
    }, () => {
      $('#locationSubnav').removeClass('open');
      $('#locationLink').removeClass('open');
    });

    $('#locationSubnav').hover(() => {
      if($(window).width() <= 750) {
        return;
      }
      $('#locationSubnav').addClass('open');
      $('#locationLink').addClass('open');
    }, () => {
      $('#locationSubnav').removeClass('open');
      $('#locationLink').removeClass('open');
    });

    $('#mediasLink').hover(() => {
      if($(window).width() <= 750) {
        return;
      }
      $('#mediasSubnav').addClass('open');
      $('#mediasLink').addClass('open');
    }, () => {
      $('#mediasSubnav').removeClass('open');
      $('#mediasLink').removeClass('open');
    });

    $('#mediasSubnav').hover(() => {
      if($(window).width() <= 750) {
        return;
      }
      $('#mediasSubnav').addClass('open');
      $('#mediasLink').addClass('open');
    }, () => {
      $('#mediasSubnav').removeClass('open');
      $('#mediasLink').removeClass('open');
    });

    $('#fr').click(() => {
      i18next.changeLanguage("fr");
      location.reload();
    });

    $('#nl').click(() => {
      i18next.changeLanguage("nl");
      location.reload();
    });

    var swiper = new Swiper('.swiper-container', {
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      loop: true,
      effect: 'slide',
      grabCursor: true,
      autoplay: {
        delay: 4500
      },
    });

    var sr = ScrollReveal({ delay: 300, interval: 200, scale: 0.85 });

    sr.reveal('.card', { mobile: false });
    sr.reveal('.card', { desktop: false, container: '.site-content' });

    $("#lightgallery").lightGallery();
    $('#5-way-football-field').lightGallery();
    $('#5-way-football-field-gallery').lightGallery();
    $('#arena-1vs1-2vs2').lightGallery();
    $('#arena-1vs1-2vs2-gallery').lightGallery();
    $('#precision-shooting-stand').lightGallery();
    $('#precision-shooting-stand-gallery').lightGallery();
    $('#power-shooting-stand').lightGallery();
    $('#power-shooting-stand-gallery').lightGallery();
    $('#foot-tennis').lightGallery();
    $('#foot-tennis-gallery').lightGallery();
    $('#foot-bowling').lightGallery();
    $('#giant-dart').lightGallery();
    $('#giant-dart-gallery').lightGallery();
});